export const aiIconRowOne=[
    {
        icon:require('@/assets/imgcaptions/icon_gongneng1.svg'),
        name:'智能识别字幕'
    },
    {
        icon:require('@/assets/imgcaptions/icon_gongneng2.svg'),
        name:'自动生成时间码'
    },
    {
        icon:require('@/assets/imgcaptions/icon_gongneng3.svg'),
        name:'剧本辅助识别'
    },
    {
        icon:require('@/assets/imgcaptions/icon_gongneng4.svg'),
        name:'多文件格式导出'
    },
    {
        icon:require('@/assets/imgcaptions/icon_gongneng5.svg'),
        name:'智能人物识别'
    }
 
]

export const aiIconRowTwo=[
    {
        icon:require('@/assets/imgcaptions/icon_gongneng6.svg'),
        name:'功能展示名称'
    },
    {
        icon:require('@/assets/imgcaptions/icon_gongneng7.svg'),
        name:'功能展示名称'
    },
    {
        icon:require('@/assets/imgcaptions/icon_gongneng8.svg'),
        name:'功能展示名称'
    },
    {
        icon:require('@/assets/imgcaptions/icon_gongneng9.svg'),
        name:'功能展示名称'
    },
    {
        icon:require('@/assets/imgcaptions/icon_gongneng10.svg'),
        name:'多语种翻译'
    },
]
export const aitext=['3GP', 'ASF', 'AVI','DAT','DV' ,'FLV','F4V','GIF','M2T', 'M4V','MJ2' , 'MJPEG', 'MKV', 'MOV',
     'MP4', 'MPE', 'MPG','MPEG', 'MTS', 'OGG', 'QT', 'RM', 'RMVB', 'SWF', 'TS', 'VOB','WMV', 'WEBM']

export const languageList=[
    { text: '中文', value: 'cn' },
    { text: '英语', value: 'en' },
    { text: '韩语', value: 'ko' },
    { text: '法语', value: 'fr' },
    { text: '德语', value: 'de' },
    { text: '俄语', value: 'ru' },
    { text: '泰语', value: 'th' },
    { text: '西班牙语', value: 'es' },
    { text: '意大利语', value: 'it' },
    { text: '日语', value: 'ja' },
]
export const controlType=[
    { text: '编辑时间', value: 'modifyTime' },
    { text: '编辑角色', value: 'modifyRole' },
    { text: '原文编辑', value: 'modifyOriginalText' },
    { text: '译文编辑', value: 'modifyTranslationText' },
    { text: '原文标注颜色编辑', value: 'modifyOriginalColor' },
    { text: '译文标注颜色编辑', value: 'modifyTranslationColor' },
    { text: '添加', value: 'add' },
    { text: '删除', value: 'delete' },
    { text: '撤销删除', value: 'restore' },
]

export const applicationDetail={
    caption:{
        name:'123AI灵犀字幕',
        version:'2.3.9'
    },
    chatGtp:{
        name:'123AI万物智文',
        version:'1.1.0'
    },
    aiVoice:{
        name:'123AI幻音系统',
        version:'1.1.1'
    }
}

